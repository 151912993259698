import axios from 'redaxios';
import { WeatherForecast } from 'types/forecastAPI';

const getForecastDataByLocationId = async (
  locationId: number
): Promise<WeatherForecast> => {
  const res = await axios.get(
    `https://forecast.buienradar.nl/2.0/forecast/${locationId}`
  );
  return res.data;
};

export default getForecastDataByLocationId;
