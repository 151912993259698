import AreaGraph from 'components/Graphs/AreaGraph';
import Heading from 'components/Heading/Heading';
import { useQuery } from 'react-query';
import {
	GraphDataForecastResponseModel,
	GraphDataResponseModel,
	graphType,
} from '../../../types/graphdataAPI';
import { useLocationState } from 'utils/contexts/LocationsContext';
import { getGraphDataByLocation } from 'utils/getGraphData';

import styles from './RainGraph.module.scss';

interface IbestCandidate extends GraphDataForecastResponseModel {
	index: number;
}

interface IrainCategory {
	index: 0 | 1 | 2 | 3;
	text: string;
}

export function RainGraph() {
	const { currentLocation } = useLocationState();
	const { lat, lon } = currentLocation.location ?? {
		lat: undefined,
		lon: undefined,
	};
	const { data, isLoading, isError } = useQuery(
		['graph', lat, lon],
		() =>
			getGraphDataByLocation({
				graphType: graphType.RainHistoryForecast,
				latitude: lat,
				longitude: lon,
			}),
		{
			keepPreviousData: true,
		}
	);

	if (isLoading || isError || !data) return null;

	return (
		<section className={styles.RainGraphContainer}>
			<header>
				<RainGraphHeading data={data} />
			</header>
			<AreaGraph data={data} />
		</section>
	);
}

function rainCategory(precipitation: number | undefined | null): IrainCategory {
	if (precipitation) {
		if (precipitation === 0)
			return {
				index: 0,
				text: 'droog',
			};
		if (precipitation <= 2)
			return {
				index: 1,
				text: 'lichte',
			};
		if (precipitation <= 10)
			return {
				index: 2,
				text: 'matige',
			};
		return {
			index: 3,
			text: 'zware',
		};
	}
	return {
		index: 0,
		text: 'droog',
	};
}

function RainGraphHeading({ data }: { data: GraphDataResponseModel }) {
	if (!data.forecasts) return null;
	const bestCandidate = data.forecasts.reduce(
		(prev, current, currentIndex) => {
			if (prev.precipitation && current.precipitation) {
				return prev.precipitation > current.precipitation
					? prev
					: { ...current, index: currentIndex };
			} else {
				return { ...current, index: currentIndex };
			}
		}
	) as IbestCandidate;
	const category = rainCategory(bestCandidate.precipitation);
	let headingTitle = 'Geen neerslag verwacht';

	if (category.index > 0) {
		if (bestCandidate.index === 0) {
			headingTitle = `${category.text} neerslag verwacht`;
		} else {
			headingTitle = `Na ${getTime(
				data.forecasts[bestCandidate.index - 1]?.datetime
			)} ${category.text} neerslag verwacht`;
		}
	}

	return (
		<Heading variant="h3" margin="0 0 17px 0">
			{headingTitle}
		</Heading>
	);
}

function getTime(dateTime: string | undefined | null): string {
	return dateTime
		? new Date(dateTime).toLocaleTimeString('nl-NL', {
				hour: '2-digit',
				minute: '2-digit',
		  })
		: '';
}
