import { useRef, useState } from 'react';
import { colors } from 'styles/theme';
import { GraphDataResponseModel } from 'types/graphdataAPI';

import styles from './Tooltip.module.scss';

interface IToolTipData {
	value: number;
	time: string;
	xPos: number;
}

interface IToolTip {
	data: GraphDataResponseModel;
	width: number;
	height: number;
}

export default function ToolTip({ data, width, height }: IToolTip) {
	const [tooltipData, setTooltipData] = useState<IToolTipData | null>(null);
	const tooltipRef = useRef<SVGGraphicsElement | null>(null);
	function evtHandler(evt) {
		if (tooltipRef?.current) {
			const pt = new DOMPointReadOnly(
				evt.clientX,
				evt.clientY
			).matrixTransform(tooltipRef.current.getScreenCTM()?.inverse());
			setTooltipData({
				time: evt.target.getAttribute('data-time'),
				value: evt.target.getAttribute('data-precipitation'),
				xPos: pt.x,
			});
		}
	}

	if (!tooltipData) return null;

	return (
		<g id="tooltip" ref={tooltipRef}>
			<ToolTipMarker tooltipData={tooltipData} />
			<g
				fillOpacity="0"
				strokeWidth={0}
				onMouseMoveCapture={evtHandler}
				onMouseLeave={() => setTooltipData(null)}
			>
				<ToolTipDatum data={data} width={width} height={height} />
			</g>
		</g>
	);
}

function ToolTipMarker({ tooltipData }: { tooltipData: IToolTipData }) {
	if (!tooltipData) return null;
	const { xPos, time, value } = tooltipData;
	const labelPosition = {
		x: xPos >= 180 ? -60 : 0,
	};
	return (
		<g
			id="tooltipmarker"
			x={100}
			y={100}
			transform={`translate(${xPos - 1},20)`}
		>
			<line
				stroke={colors.kingsOrange}
				strokeWidth={1}
				x1={0}
				x2={0}
				y1={0}
				y2={80}
			/>
			<g id="tooltiplabel" transform={`translate(${labelPosition.x},0)`}>
				<rect
					width={60}
					height={30}
					x={0}
					y={0}
					fill={colors.kingsOrange}
				></rect>
				<text
					width={60}
					height={10}
					x={14}
					y={16}
					className={styles.tooltipTime}
				>
					{new Date(time).toLocaleTimeString('nl-NL', {
						hour: '2-digit',
						minute: '2-digit',
					})}
				</text>
				<text
					width={60}
					height={10}
					x={6}
					y={26}
					className={styles.tooltipValue}
				>
					{value} mm/u
				</text>
			</g>
		</g>
	);
}

function ToolTipDatum({ data, width, height }) {
	const rectWidth = width / data.forecasts.length;
	const rectHeight = height;

	return data.forecasts.map((item, index) => {
		const xPos = rectWidth * index;
		return (
			<rect
				key={xPos}
				width={rectWidth}
				height={rectHeight}
				y={0}
				x={xPos}
				data-time={item.datetime}
				data-precipitation={item.precipitation
					.toFixed(1)
					.replace('.', ',')}
			></rect>
		);
	});
}
