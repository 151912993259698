import { colors } from 'styles/theme';
import { GraphDataResponseModel } from 'types/graphdataAPI';
import ToolTip from './Tooltip';

import styles from './AreaGraph.module.scss';

interface RainGraphData {
	precipitation: number;
	time: string;
}

export function AreaGraph({
	data,
	width = 336,
	height = 100,
}: {
	data: GraphDataResponseModel;
	width?: number;
	height?: number;
}) {
	const svgHeightWithLabels = height + 20;
	const transformedData = transformData(data);
	const dataPoints = convertDataToPoints({
		data: transformedData,
		width,
		height,
	});
	const amountOfYLines = 19;

	return (
		<svg
			aria-labelledby="title"
			viewBox="0 0 336 120"
			height={svgHeightWithLabels}
			role="presentation"
			className={styles.graph}
		>
			<title id="title" lang="nl">
				Regen vooruitzicht
			</title>
			<g
				id="raingraph"
				fill={colors.skyBlue}
				stroke={colors.skyBlue}
				strokeWidth="1"
			>
				<polyline points={dataPoints} />
			</g>
			<g id="xGrid" strokeDasharray={0}>
				<Lines
					width={width}
					height={height}
					amount={amountOfYLines}
					axis="x"
				/>
			</g>
			<g id="yGrid" strokeDasharray={0}>
				<Lines width={width} height={height} amount={3} axis="y" />
			</g>
			<g id="legend">
				<text x={width - 40} y={15} className={styles.graphText}>
					Zwaar
				</text>
				<text
					x={width - 30}
					y={height - 5}
					className={styles.graphText}
				>
					Licht
				</text>
			</g>
			<g id="labels">
				<TimeLabels
					data={transformedData}
					width={width}
					height={height}
					amount={amountOfYLines}
				/>
			</g>
			<NowLine data={data} height={height} width={width} />
			<ToolTip data={data} width={width} height={height} />
		</svg>
	);
}

function transformPrecipitationValue({ value, transformRule }) {
	return (
		((value - transformRule.minFrom) *
			(transformRule.maxTo - transformRule.minTo)) /
			(transformRule.maxFrom - transformRule.minFrom) +
		transformRule.minTo
	);
}

export function transformData(data: GraphDataResponseModel): RainGraphData[] {
	const { forecasts } = data;
	const forecastMap = forecasts
		? forecasts.map((forecast) => forecast.precipitation ?? 0)
		: [0];
	const highest = Math.max(...forecastMap);
	/* We transform/scale the precipitation values in order to have to lower values of precipitation
	 * show up better in the graph. Otherwise a few mm of rain would result in a spike of a couple of pixels which is hardly noticeable
	 */
	const transformRules = [
		{ minFrom: 0.000001, maxFrom: 0.5, minTo: 13, maxTo: 13 },
		{ minFrom: 0.5, maxFrom: 2, minTo: 13, maxTo: 40 },
		{ minFrom: 2, maxFrom: 10, minTo: 40, maxTo: 70 },
		{ minFrom: 10, maxFrom: highest, minTo: 70, maxTo: 100 },
	];

	if (!forecasts) {
		return [
			{
				precipitation: 0,
				time: '',
			},
		];
	}

	return forecasts.map((forecast) => {
		let newPrecipitation = forecast.precipitation ?? 0;
		const transformRuleFound = transformRules.find(
			(transformRule) =>
				newPrecipitation <= transformRule.maxFrom &&
				newPrecipitation >= transformRule.minFrom
		);

		if (transformRuleFound) {
			newPrecipitation = transformPrecipitationValue({
				value: forecast.precipitation,
				transformRule: transformRuleFound,
			});
		}

		const forecastTime = forecast.datetime
			? new Date(forecast.datetime)
			: new Date();

		return {
			precipitation: newPrecipitation,
			time: forecastTime.toLocaleTimeString('nl-NL', {
				hour: '2-digit',
				minute: '2-digit',
			}),
		};
	});
}

function convertDataToPoints({
	data,
	width,
	height,
}: {
	data: RainGraphData[];
	width: number;
	height: number;
}): string {
	const xStep = width / data.length;
	const points = data.map((record, index) => {
		const x = index * xStep;
		const y = height - record.precipitation;
		return `${x},${y}`;
	});

	/* add extra points at the start and end so we can 'start' & 'close' the area graph nicely.
	 */
	points.unshift(`0,${height}`);
	points.push(`${width},${height}`);
	return points.join(' ');
}

function TimeLabels({
	data,
	width,
	height,
	amount,
}: {
	data: RainGraphData[];
	width: number;
	height: number;
	amount: number;
}) {
	const labels: RainGraphData[] = [];
	const lineSteps = 6;
	const yOffset = 15;
	const xOffset = 16;
	const yPos = height + yOffset;
	const chunkWidth = width / amount;
	const xPosArray: number[] = [];

	data.forEach((item, index) => {
		if (index === 0) {
			labels.push(item);
		} else if (index % lineSteps === 0) {
			labels.push(item);
		}
	});

	for (let i = 0; i < amount; i++) {
		if (i % 3 === 0) {
			const xPos = i === 0 ? chunkWidth * i : chunkWidth * i - xOffset;
			xPosArray.push(xPos);
		}
	}

	return (
		<>
			{labels.map((label, index) => {
				return (
					<text
						y={yPos}
						x={xPosArray[index]}
						key={label.time}
						className={styles.graphTimeLabel}
					>
						{label.time}
					</text>
				);
			})}
		</>
	);
}

function Lines({
	width,
	height,
	amount,
	axis = 'y',
}: {
	width: number;
	height: number;
	amount: number;
	axis: 'x' | 'y';
}) {
	const lines: unknown[] = [];

	for (let i = 0; i <= amount; i++) {
		const xStart = axis === 'y' ? 0 : (width / amount) * i;
		const xEnd = axis === 'y' ? width : (width / amount) * i;
		const yStart = axis === 'y' ? (height / amount) * i : 0;
		const yEnd = axis === 'y' ? (height / amount) * i : height;
		let strokeColor = '';
		if (axis === 'y') {
			strokeColor = i === 0 || i === amount ? '#c4c4c4' : '#e7e7e7';
		} else {
			strokeColor = i % 3 === 0 ? '#c4c4c4' : '#e7e7e7';
		}

		lines.push(
			<line
				key={`line${i}`}
				x1={xStart}
				x2={xEnd}
				y1={yStart}
				y2={yEnd}
				strokeWidth={1}
				stroke={strokeColor}
			></line>
		);
	}

	return <>{lines}</>;
}

function NowLine({
	data,
	height,
	width,
}: {
	data: GraphDataResponseModel;
	width: number;
	height: number;
}) {
	const now = new Date();
	const { forecasts } = data;
	if (!forecasts) return null;
	const startDate = forecasts[0].datetime
		? new Date(forecasts[0].datetime)
		: new Date();
	const forecastDatetime = forecasts[forecasts.length - 1].datetime;
	const endDate = forecastDatetime ? new Date(forecastDatetime) : new Date();

	//if the current date falls outside of the provided data range we do not show the 'nu'-line
	if (now < startDate && now > endDate) return null;

	const milliseconds = 1000;
	const seconds = 60;
	const rangeInMinutes =
		(endDate.getTime() - startDate.getTime()) / milliseconds / seconds;
	const nowInMinutes =
		(now.getTime() - startDate.getTime()) / milliseconds / seconds;
	const widthPerMinute = width / rangeInMinutes;
	const x = nowInMinutes * widthPerMinute;

	return (
		<g id="nowline">
			<line
				y1={height - 80}
				y2={height}
				x1={x}
				x2={x}
				stroke={colors.waterBlue}
				strokeWidth={1}
			/>
			<text x={x - 10} y={height - 85} className={styles.nowLabel}>
				Nu
			</text>
		</g>
	);
}

export default AreaGraph;
