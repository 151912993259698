import Heading from 'components/Heading/Heading';
import RelevantNumbers from 'components/RelevantNumbers/RelevantNumbers';
import TextLink from 'components/TextLink/TextLink';
import { suntimes } from '../PlaceSuntimes/PlaceSuntimes';
import ArrowIcon from '/public/icons/arrow.svg';
import useGetBaseUrl from 'utils/hooks/useGetBaseUrl';
import { WeatherForecast } from 'types/forecastAPI';
import { GeoLocation } from 'types/locationAPI';

import styles from './Today.module.scss';

export default function Today({
	data,
	currentLocation,
}: {
	data: WeatherForecast;
	currentLocation: GeoLocation;
}) {
	const baseUrl = useGetBaseUrl();
	if (!data || !data?.days || !data?.days[0]) return null;
	const { sunrise, sunset } = data.days[0];
	const formattedSunrise = suntimes(sunrise);
	const formattedSunset = suntimes(sunset);
	const { nowrelevant } = data;
	const sunRadarLink = `${baseUrl}/zon-en-wolken/zonradar/24uurs`;

	return (
		<section className={styles.todayContainer}>
			<header>
				<Heading variant="h4">Vandaag in cijfers</Heading>
			</header>
			<ul className={styles.todaySunList}>
				<li className={styles.todaySunListItem}>
					<a href={sunRadarLink}>
						<figure className={styles.todaySun} data-type="sunrise">
							<img
								src="https://cdn.buienradar.nl/resources/images/icons/sunrise.png"
								width="40"
								height="20"
								alt=""
								loading="lazy"
							/>
							<figcaption>
								<time dateTime={formattedSunrise}>
									{formattedSunrise}
								</time>
								, zonsopkomst
							</figcaption>
						</figure>
						<ArrowIcon />
						<figure className={styles.todaySun} data-type="sunset">
							<img
								src="https://cdn.buienradar.nl/resources/images/icons/sunset.png"
								width="40"
								height="20"
								alt=""
								loading="lazy"
							/>
							<figcaption>
								<time dateTime={formattedSunset}>
									{formattedSunset}
								</time>
								, zonsondergang
							</figcaption>
						</figure>
					</a>
				</li>
				<RelevantNumbers nowrelevant={nowrelevant} />
			</ul>
			<TextLink appearance="text" href="/">
				Weeroverzicht van {currentLocation.name}
			</TextLink>
		</section>
	);
}
