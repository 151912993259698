import AdSlot, { BannerType } from 'components/AdSlot';
import Button from 'components/Button';
import Heading from 'components/Heading/Heading';
import TextLink from 'components/TextLink/TextLink';
import { RainGraph } from './RainGraph';
import { useQuery } from 'react-query';
import { GeoLocation } from 'types/locationAPI';
import { useLocationState } from 'utils/contexts/LocationsContext';
import getForecastDataByLocationId from 'utils/getForecast';
import useIsWindowSize from 'utils/hooks/useIsWindowSize';
import LocationSearch from './LocationSearch';
import Today from './Today';

const Forecast = dynamic(
	() => import('./Forecast').then((mod) => mod.Forecast),
	{
		ssr: false,
	}
);

import cssVariables from 'styles/variables.module.scss';
import styles from './MyWeather.module.scss';
import dynamic from 'next/dynamic';

export enum EnumMyWeatherLinkTypes {
	Today = '/overzicht',
	'5day' = '/5daagse',
	'14day' = '/14daagse',
	'Rainradar' = '/buienradar',
	'Overview' = '',
}

export const myWeatherLink = ({
	currentLocation,
	type,
}: {
	currentLocation: GeoLocation;
	type: EnumMyWeatherLinkTypes;
}): string => {
	const { asciiname, countrycode, id } = currentLocation;
	if (!asciiname || !countrycode) return '/';
	return `/weer/${asciiname}/${countrycode.toLowerCase()}/${id}${type}`;
};

export default function MyWeather() {
	const isInDesktopWindow = useIsWindowSize({
		mediaQuery: cssVariables.desktopS,
	});
	const { currentLocation } = useLocationState();

	const { data, error } = useQuery(
		['forecastData', currentLocation.id],
		() => getForecastDataByLocationId(currentLocation.id),
		{
			retry: false,
		}
	);

	if (!currentLocation) {
		return null;
	}

	return (
		<section className={styles.MyWeatherContainer}>
			{isInDesktopWindow ? <LocationSearch /> : null}
			<header>
				<Heading variant="h3" classNameExt={styles.MyWeatherTitle}>
					Mijn weer in {currentLocation.name}
				</Heading>
			</header>
			{data && !error ? (
				<Forecast
					forecastData={data}
					currentLocation={currentLocation}
				/>
			) : null}
			<RainGraph />
			{!isInDesktopWindow && data && !error ? (
				<Today data={data} currentLocation={currentLocation} />
			) : null}
			<div className={styles.myWeatherAdSlot}>
				<AdSlot
					id="br-ad-home-myweather-small"
					type={BannerType.Rectangle}
				/>
			</div>
			{isInDesktopWindow ? (
				<MoreAbout currentLocation={currentLocation} />
			) : null}
		</section>
	);
}

function MoreAbout({ currentLocation }: { currentLocation: GeoLocation }) {
	return (
		<section>
			<header>
				<Heading classNameExt={styles.myWeatherHeading} variant="h4">
					Meer weer van {currentLocation.name}
				</Heading>
			</header>
			<ul className={styles.myWeatherList}>
				<li>
					<TextLink
						appearance="text"
						href={myWeatherLink({
							currentLocation,
							type: EnumMyWeatherLinkTypes.Rainradar,
						})}
					>
						Buienradar
					</TextLink>
				</li>
				<li>
					<TextLink
						appearance="text"
						href={myWeatherLink({
							currentLocation,
							type: EnumMyWeatherLinkTypes['5day'],
						})}
					>
						5-daagse per uur
					</TextLink>
				</li>
			</ul>
			<Button
				primary
				href={myWeatherLink({
					currentLocation,
					type: EnumMyWeatherLinkTypes['Overview'],
				})}
			>
				Uitgebreid overzicht {currentLocation.name}
			</Button>
		</section>
	);
}
