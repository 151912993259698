export interface ActualArchive {
	/** Format: double */
	timeOffset?: number;
	/** Format: int32 */
	stationCode?: number;
	stationName?: string | null;
	observations?: ActualArchiveObservation[] | null;
}
export interface ActualArchiveObservation {
	/** Format: date-time */
	observationDatetime?: string;
	values?: ActualArchiveValues;
}
export interface ActualArchiveValues {
	/** Format: double */
	windDirection?: number | null;
	/** Format: double */
	windSpeed10m?: number | null;
	/** Format: double */
	totalCloudCover?: number | null;
	/** Format: double */
	ambientTemperature1Point5Meter?: number | null;
	/** Format: int32 */
	wawaWeatherCode?: number | null;
	/** Format: double */
	relativeHumidity1Point5Meter?: number | null;
	/** Format: double */
	cloudBaseCeilometer?: number | null;
	/** Format: double */
	visibility?: number | null;
	/** Format: int32 */
	pastWeatherIndicator?: number | null;
	/** Format: double */
	cloudAmountCeilometerFirstLayer?: number | null;
	/** Format: double */
	airPressureSeaLevel?: number | null;
	/** Format: double */
	windGusts?: number | null;
	/** Format: double */
	windGustsMax?: number | null;
	/** Format: double */
	diffuseSolarUVARadiation?: number | null;
	/** Format: double */
	precipitationIntensity?: number | null;
	/** Format: double */
	groundTemperature10CM?: number | null;
}
export interface GraphDataBorderResponseModel {
	title?: string | null;
	/** Format: int32 */
	lower?: number;
	/** Format: int32 */
	upper?: number;
}
export interface GraphDataExtraResponseModel {
	sunrise?: string | null;
	sunset?: string | null;
}
export interface GraphDataForecastResponseModel {
	datetime?: string | null;
	utcDateTime?: string | null;
	/** Format: double */
	precipitation?: number | null;
	/** Format: double */
	precipation?: number | null;
	/** Format: int32 */
	original?: number | null;
	/** Format: int32 */
	value?: number | null;
}
export interface GraphDataResponseModel {
	color?: string | null;
	/** Format: double */
	lat?: number;
	/** Format: double */
	lon?: number;
	borders?: GraphDataBorderResponseModel[] | null;
	/** Format: double */
	timeOffset?: number;
	/** Format: int32 */
	radius?: number;
	isFromCache?: boolean | null;
	forecasts?: GraphDataForecastResponseModel[];
	emptytext?: string | null;
	/** Format: date-time */
	cachedUtc?: string | null;
	/** Format: date-time */
	createdUtc?: string | null;
	/** Format: date-time */
	lastRefreshUtc?: string | null;
	extra?: GraphDataExtraResponseModel;
	/** Format: int32 */
	elapsedMs?: number;
}
export interface PlumeBasic {
	/** Format: date-time */
	modelDate?: string;
	region?: string | null;
	dataType?: PlumeDataType;
	cloudPercentages?: number[][] | null;
	highestValues?: number[] | null;
	lowestValues?: number[] | null;
	averageValues?: number[] | null;
	operationalRun?: number[] | null;
	controlRun?: number[] | null;
	dateTimes?: string[] | null;
	/** Format: double */
	timeOffset?: number;
}
/**
 * Format: int32
 * @enum {integer}
 */
export type PlumeDataType =
	| 11003
	| 11004
	| 11011
	| 11012
	| 11041
	| 12004
	| 12006
	| 12199
	| 12200
	| 13011
	| 13233
	| 13241
	| 20010
	| 90001;

export interface PlumeFull {
	/** Format: date-time */
	modelDate?: string;
	region?: string | null;
	dataType?: PlumeDataType;
	runs?: number[][] | null;
	cloudPercentages?: number[][] | null;
	highestValues?: number[] | null;
	lowestValues?: number[] | null;
	averageValues?: number[] | null;
	dateTimes?: string[] | null;
	/** Format: double */
	timeOffset?: number;
	/** Format: int32 */
	operationalRunIndex?: number;
	/** Format: int32 */
	controlRunIndex?: number;
}

export enum graphType {
	Rain3Hour = 'Rain3Hour',
	RainHistory1Hour = 'RainHistory1Hour',
	Rain24Hour = 'Rain24Hour',
	Rain5Day = 'Rain5Day',
	RainHistoryForecast = 'RainHistoryForecast',
	Sun3Hour = 'Sun3Hour',
	Sun24Hour = 'Sun24Hour',
	Sun5Day = 'Sun5Day',
}
