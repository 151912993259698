import { useQuery } from 'react-query';
import Heading from 'components/Heading/Heading';
import getForecastDataByLocationId from 'utils/getForecast';
import useIsWindowSize from 'utils/hooks/useIsWindowSize';
import { useLocationState } from 'utils/contexts/LocationsContext';

import cssVariables from 'styles/variables.module.scss';
import styles from './PlaceSuntimes.module.scss';

type SunTimeType = `${string}:${string}` | string;

export function suntimes(date: string | undefined | null): SunTimeType {
	if (!date) {
		return '';
	}
	const suntime = new Date(date);
	const hours = ('0' + String(suntime.getHours())).slice(-2);
	const minutes = ('0' + String(suntime.getMinutes())).slice(-2);

	return `${hours}:${minutes}`;
}

export function PlaceSuntimesWithData() {
	const { currentLocation } = useLocationState();
	const isInWindow = useIsWindowSize({
		mediaQuery: cssVariables.desktopSMax,
	});

	const { data, isLoading, isError } = useQuery(
		['forecastData', currentLocation.id],
		() => getForecastDataByLocationId(currentLocation.id),
		{
			retry: false,
			enabled: isInWindow,
		}
	);

	if (!data || !data.days) return null;
	const { sunrise, sunset } = data?.days[0] ?? {};
	if (!isInWindow || isLoading || isError || !sunrise || !sunset) return null;

	const formattedSunrise = suntimes(sunrise);
	const formattedSunset = suntimes(sunset);

	return (
		<PlaceSuntimes sunrise={formattedSunrise} sunset={formattedSunset} />
	);
}

export default function PlaceSuntimes({
	sunrise,
	sunset,
}: {
	sunrise: SunTimeType;
	sunset: SunTimeType;
}) {
	return (
		<div className={styles.suntimesContainer}>
			<Heading variant="h3" classNameExt={styles.suntimesHeading}>
				Zonsopkomst en -ondergang
			</Heading>
			<div className={styles.suntimes}>
				<div className={styles.suntime}>
					<img
						src="https://cdn.buienradar.nl/resources/images/icons/sunrise.png"
						width="100"
						height="50"
						alt=""
						loading="lazy"
					/>
					Komt op om {sunrise}
				</div>
				<div className={styles.suntime}>
					<img
						src="https://cdn.buienradar.nl/resources/images/icons/sunset.png"
						width="100"
						height="50"
						alt=""
						loading="lazy"
					/>
					Gaat onder om {sunset}
				</div>
			</div>
		</div>
	);
}
