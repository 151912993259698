import axios from 'redaxios';
import { GraphDataResponseModel, graphType } from 'types/graphdataAPI';

interface IGraphDataByLocation {
	graphType: keyof typeof graphType;
	latitude: number | undefined;
	longitude: number | undefined;
}

const maxDecimals = 2;

export async function getGraphDataByLocation({
	graphType,
	latitude,
	longitude,
}: IGraphDataByLocation): Promise<GraphDataResponseModel> {
	if (latitude && longitude) {
		const response = await axios.get(
			`https://graphdata.buienradar.nl/2.0/forecast/geo/${graphType}?lat=${latitude.toFixed(
				maxDecimals
			)}&lon=${longitude.toFixed(maxDecimals)}&btc=12345timestamp`
		);
		return response.data;
	} else {
		return Promise.reject('Please provide latitude/ longitude');
	}
}
