import ArrowIcon from '/public/icons/arrow.svg';
import useGetBaseUrl from 'utils/hooks/useGetBaseUrl';

import styles from './RelevantNumbers.module.scss';

export default function RelevantNumbers({ nowrelevant }) {
	const baseUrl = useGetBaseUrl();

	return nowrelevant.values.map((item) => {
		const { value, type } = item;
		const cssValue = getCssValue(item);

		return (
			<li key={type} className={styles.relevantItem}>
				<a href={baseUrl + relevantMap[type].link}>
					<span
						className={`
              ${styles.relevantValue}
              ${
					styles[
						relevantMap[type].className.replace('{value}', cssValue)
					]
				}
            `}
						data-value={value}
					>
						{value}
					</span>
					<span>{relevantMap[type].title}</span>
					<ArrowIcon />
				</a>
			</li>
		);
	});
}

export function getCssValue(item) {
	const { value, type } = item;
	if (type === 'maxtemperature' || type === 'temperature') {
		let roundedValue = Math.round(parseFloat(value));
		if (roundedValue > 38) return 38;
		if (roundedValue < -25) roundedValue = -25;
		if (roundedValue < 0) return `cold${roundedValue * -1}`;

		return roundedValue;
	}
	return parseInt(value);
}

const relevantMap = {
	maxtemperature: {
		title: 'Max. temperatuur (°C)',
		link: '/temperatuur/actuele-temperatuur',
		className: 'temperature{value}',
	},
	temperature: {
		title: 'Temperatuur (°C)',
		link: '/temperatuur/actuele-temperatuur',
		className: 'temperature{value}',
	},
	pollenindex: {
		title: 'Hooikoorts',
		link: '/gezondheid/pollen',
		className: 'pollen{value}',
	},
	uvindex: {
		title: 'Zonkracht (UV)',
		link: '/gezondheid/zonkracht-uv',
		className: 'uvindex{value}',
	},
	beaufort: {
		title: 'Windsnelheid (Bft)',
		link: '/wind/actuele-wind',
		className: 'bft{value}',
	},
	bbqindex: {
		title: 'BBQ index',
		link: '/evenementen/bbq-radar',
		className: 'bbqradar{value}',
	},
	visibility: {
		title: 'Zicht (meter)',
		link: '/zon-en-wolken/zicht-en-mist',
		className: 'visibility-low',
	},
};
